.signup-wrapper {
    position: relative;
    height: 60rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-7001);
    overflow-x: hidden;
}

.signup-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
}

.signup-heading h2 {
    font-family: 'Montserrat Alternates', sans-serif;
    color: var(--primary-1001);
    background-color: var(--primary-4001);
    padding: 0.5rem 1.5rem;
    border-radius: 40px;
}

.signup-heading p {
    color: var(--primary-3001);
    text-align: center;
}

.error {
    color: #fc8181;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
}

.signup-wrapper form {
    width: 40%;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 5px;
}

.signup-wrapper form label {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    display: block;
    text-align: left;
    margin: 1rem 0 0.2rem;
}

.signup-wrapper input {
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    color: var(--primary-3001);
    border: none;
    border-radius: 5px;
    border-bottom: 2px solid var(--primary-7001);
    background-color: var(--primary-1001);
    outline: none;
}

.signup-wrapper input:focus,
.signup-wrapper select:focus {
    border-color: var(--primary-5001);
}

.signup-wrapper input::placeholder,
.signup-wrapper select::placeholder {
    color: var(--primary-7001);
}

.signup-wrapper button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    background-color: var(--primary-4001);
    color: var(--primary-1001);
    border: none;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.signup-wrapper button:hover {
    transform: scale(0.98);
}

.signup-wrapper input.input-error,
.signup-wrapper select.input-error {
    border-color: #fc8181;
}

.signup-wrapper .sign-hero-2 {
    width: 55%;
    height: 100%;
    background-color: var(--primary-1001); 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.signup-wrapper .sign-hero-2 img {
    width: 90%;
}

.signup-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 5rem);
    background-color: var(--primary-8001);
}

form button {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .signup-heading h2 {
        background-color: var(--primary-3001);
        color: var(--primary-1001);
    }

    .signup-heading {
        border-bottom: 2px solid var(--primary-1001);
    }

    .signup-wrapper button {
        background-color: var(--primary-3001);
        color: var(--primary-1001);
    }

    .signup-wrapper .sign-hero-2 {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: transparent;
        z-index: -2;
    }

    .signup-wrapper form {
        width: 80%;
        background-color: var(--primary-7001) ;
        border-radius: 10px;
    }

    .signup-wrapper {
        height: 60rem;
        background-color: transparent;
    }
}

@media screen and (max-width: 830px) {
    .signup-wrapper .sign-hero-2 img {
        position: absolute;
        top: 8%;
        width: 200%;
    }
}

@media screen and (max-width: 500px) {
    .signup-wrapper form {
        width: 95%;
    }

    .signup-wrapper .sign-hero-2 img {
        top: 14%;
    }

    .signup-wrapper p {
        font-size: 0.83rem;
    }
}