.notification-container {
    display: flex;
    height: fit-content;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.notification-container .faq-row-wrapper {
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 2rem;
}

.notification-1 {
    width: 100%;
    height: fit-content;
}

.notification-2 h3 {
    padding-left: 5%;
    font-family: 'Comfortaa', cursive;
    width: 80vw;
}

.notification-2-img {
    width: 20rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(30px, 30px, 0);
    }
}

.notification-2-img img {
    width: 100%;
}

.notification-details {
    width: 70vw;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-family: 'Comfortaa', cursive;
}

.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    border-bottom: 2px solid var(--primary-400) !important;
}

@media screen and (max-width: 768px) {
    .notification-details {
        width: 80vw;
    }
}

@media screen and (max-width: 500px) {
    .notification-2 h3 {
        padding-left: 5%;
        width: 100vw;
    }

    .notification-details {
        width: 90vw;
    }

    .notification-2-img {
        width: 12rem;
    }

    .notification-container .row-title-text {
        font-size: 0.9rem;
    }
}