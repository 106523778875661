.investment-container {
    display: flex;
    height: fit-content;
    gap: 1.5rem;
    flex-direction: column;
    justify-content: space-between;
}

.investment-1 {
    width: 100%;
    height: fit-content;
}

.investment-1 h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    width: 100%;
    padding: 0.5rem 2rem;
    background: var(--primary-500);
    border-radius: 0 0 10px 10px;
    color: var(--primary-100);
    letter-spacing: 3px;
}

.investment-2 {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 0 auto;
}

.investment-2-1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.investmentbalance2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: column;
    margin: 1rem auto;
    width: 100%;
    height: 23rem;
    background-color: var(--primary-300);
    border-radius: 10px;
    filter: drop-shadow(12px 7px 39px rgba(61, 89, 154, 0.58));
}

.investmentbalance2 p {
    color: var(--primary-200);
    font-weight: 600;
}

.investmentbalance2-1 {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: column;
    margin: 1rem auto;
    border-radius: 10px;
    width: 95%;
    height: 80%;
    background-color: var(--primary-100);
    gap: 0.5rem;
}

.investmentbalance2-1-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    border-bottom: 2px ridge var(--primary-700);
}

.investmentbalance2-1-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.investmentbalance2-1-iconcontainer {
    padding: 0.6rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    border-radius: 50%;
}

.investmentbalance2-1-icon {
    font-size: 1.5rem;
}

.investmentbalance2 h2 {
    font-size: 3rem;
    letter-spacing: 2px;
    color: var(--primary-200);
}

.verified-investment {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 3rem;
    color: var(--primary-1001);
}

.investment-link {
    background-color: var(--primary-500);
    color: var(--primary-1001);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    margin-bottom: 0 !important;
}

.investment-link:hover {
    transition: all 0.4s ease-in-out;
    transform: scaleX(0.98);
    color: var(--primary-700);
}

.investment-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
}

.investment-3-1, .investment-3-2 {
    width: 50%;
    height: 13.5rem;
    background-color: var(--primary-300);
    color: var(--primary-200);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.investment-3-1 {
    justify-content: center;
}

.investment-3-2 {
    height: fit-content;
    gap: 1rem;
}

.investment-3-2 h3 {
    background-color: var(--primary-100);
    color: var(--primary-300);
    width: 100%;
    border-top: 2px solid var(--primary-300);
    border-bottom: 2px solid var(--primary-300);
    letter-spacing: 7px;
}

.investment-3-2 .swiper {
    width: 100%;
    padding: 0 2rem;
}

.mySwiperInvestment {
    margin: 0 auto;
    width: 100%;
    height: 20rem;
    padding: 0 1rem;
}

.swiperSlideInvestment {
    width: 100% !important;
    margin: 0 auto;
    height: 100%;
    padding: 1.2rem;
}

.investment-3-2-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;
    background-color: var(--primary-100);
    color: var(--primary-300);
    box-shadow: 0px 0px 10px 5px rgba(179, 202, 255, 0.8);
}

.investment-3-2-1 ul {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.investment-3-2-1 h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    width: 110%;
    margin-top: -5px;
    padding: 0.5rem;
    border-bottom: 2px solid var(--primary-300);
    background-color: var(--primary-100);
    color: var(--primary-400);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    letter-spacing: 3px;
}

.investment-3-2-1 ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.investment-3-2-1 ul li span{
    font-weight: 600;
    margin-left: 5px;
}

.investment-3-2-1 .investment-3-icon {
    color: var(--primary-400);
    font-size: 1.4rem;
    margin-right: 10px;
}

.investment-3 h2 {
    margin-top: 1rem;
    letter-spacing: 5px;
    color: var(--primary-1001);
}

.investment-4 {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 30px;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;
}

.investment-4-text {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 20px 20px;
    padding: 1.5rem;
    width: 22rem;
    height: fit-content;
    background-color: var(--primary-300);
    color: var(--primary-100);
    gap: 0.5rem;
    transition: all 0.4s ease-in-out;
    align-self: center;
    justify-self: center;
}

.investment-modal {
    padding: 1rem;
    width: 100%;
    overflow: auto;
}

.popup-overlay {
    position: relative;
    overflow-y: auto;
    margin: auto auto;
    width: 80%;
    height: 80%;
}

.popup-content {
    padding: 0rem;
    width: fit-content !important;
    border-radius: 10px;
    background-color: var(--primary-300) !important;
}

.investment-modal > ul {
    padding-left: 0rem;
    font-size: 1rem;
    line-height: 2rem;
}

.investment-modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 0px 8px;
    right: -9px;
    top: -10px;
    font-size: 1.2rem;
    background: var(--primary-500);
    border-radius: 50%;
}

.ul-modal {
    border-top: 2px solid var(--primary-1001);
    color: var(--primary-1001);
}

.investment-4-text:hover {
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
}

.investment-4-text h3 {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 3px;
    line-height: 2.2rem;
    font-weight: 600;
    background-color: var(--primary-100);
    color: var(--primary-500);
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    -webkit-box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    -moz-box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
}

.investment-modal li {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
    color: var(--primary-100);
}

.investment-4-icon {
    font-size: 1.3rem;
    color: var(--primary-100);
}

.investment-modal ul li span {
    font-weight: 500;
    margin-left: 5px;
}

.investment-modal > .content {
    width: 100%;
}

.investment-modal button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
    padding: 0.5rem;
    background-color: var(--primary-500);
    color: var(--primary-300);
}

.investment-4-text button:hover {
    transform: scale(0.98);
    background-color: var(--primary-100);
    color: var(--primary-300);
}

.investment-5 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-direction: row;
}

.investment-5-1 {
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    flex-direction: column;
    margin: 1rem auto;
    border-radius: 0 0 10px 10px;
    width: 50%;
    height: 100%;
    background-color: var(--primary-300);
    color: var(--primary-100);
    gap: 0.5rem;
}

.investment-5-1-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    border-bottom: 2px ridge var(--primary-700);
    color: var(--primary-1001);
}

.investment-5-1-iconcontainer {
    padding: 0.6rem;
    background-color: var(--primary-100);
    color: var(--primary-400);
    border-radius: 50%;
}

.investment-5-1-icon {
    font-size: 1.5rem;
}

.investment-5-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background-color: var(--primary-100);
    border-radius: 0 0 10px 10px;
}

.investment-5-2 h2 {
    color: var(--primary-400);
    margin-top: 0;
}

@media screen and (max-width: 1400px) {
    .investment-2-1 {
        width: 100%;
    }

    .investment-3-1,
    .investment-3-2 {
        width: 80%;
    }

    .investment-4 {
        width: 80%;
    }

    .investmentbalance2 {
        width: 100%;
    }

    .investment-5 {
        width: 80%;
    }
}

@media screen and (max-width: 1050px) {
    .investment-2-1 {
        width: 100%;
    }

    .investment-3-1,
    .investment-3-2 {
        width: 90%;
    }

    .investmentbalance2 {
        height: 23rem;
    }

    .investment-4 {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
    }

    .investment-5 {
        width: 90%;
    }

    .investmentbalance2 {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    .investment-2 {
        padding: 1rem;
    }

    .investment-2-1 {
        width: 100%;
    }

    .verified-kyc {
        margin-top: 1.4rem;
        gap: 1rem;
    }

    .investment-5 {
        width: 90%;
        gap: 0;
        flex-direction: column;
    }

    .investment-5-1 {
        width: 90%;
    }
}

@media screen and (max-width: 450px) {
    .investment-2-1 h2 {
        font-size: 2rem;
    }
}
