.cardrequest-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.cardrequest-1 {
    width: 100%;
    height: fit-content;
}

.cardrequest-2 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    flex-direction: row;
}

.cardnumber, .cardcvv, .cardexpiry, .cardname {
    position: absolute;
    color: var(--primary-7001);
    z-index: 1;
}

.cardname {
    bottom: 23%;
    left: 15%;
    font-size: 1rem;
}

.cardnumber {
    bottom: 45%;
    left: 15%;
    font-size: 1rem;
}

.cardexpiry {
    bottom: 31.5%;
    left: 34%;
    font-size: 0.8rem;
}

.cardcvv {
    bottom: 31.5%;
    left: 53%;
    font-size: 0.8rem;
}

.cardrequest-2-img {
    position: relative;
    width: 40%;
}

.cardrequest-2-img img {
    width: 100%;
    height: auto;
    transition: all 0.4s ease-in-out;
}

.cardrequest-2-img:hover {
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
}

.cardrequest-2-text {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 2rem;
    width: 30rem;
    height: 25rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    gap: 1rem;
    box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    -webkit-box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    -moz-box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    transition: all 0.4s ease-in-out;
}

.cardrequest-2-text:hover {
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
}

.cardrequest-2-text h3 {
    text-align: center;
    font-size: 1.4rem;
    letter-spacing: 2px;
    line-height: 3rem;
    font-weight: 600;
}

.cardrequest-2-text ul {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 400;
}

.cardrequest-icon {
    color: var(--primary-500);
    margin-right: 1rem;
}

.cardrequest-2-text button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    border: none;
    border-radius: 40px;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: fit-content;
    background-color: var(--primary-500);
    color: var(--primary-1001);
}

.cardrequest-2-text button:hover {
    transform: scale(0.98)
}

@media screen and (min-width: 1400px) {

    .cardname {
        bottom: 23%;
        left: 15%;
        font-size: 1.3rem;
    }

    .cardnumber {
        bottom: 45%;
        left: 15%;
        font-size: 1.5rem;
    }

    .cardexpiry {
        bottom: 31.5%;
        left: 34%;
        font-size: 1rem;
    }

    .cardcvv {
        bottom: 31.5%;
        left: 53%;
        font-size: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .cardrequest-container {
        display: flex;
        height: fit-content;
        flex-direction: column;
    }

    .cardrequest-1 {
        width: 100%;
        height: fit-content;
    }

    .cardrequest-2 {
        height: 100%;
        gap: 0;
        padding: 2rem 0;
        flex-direction: column;
    }

    .cardrequest-2-img {
        width: 70%;
    }

    .cardrequest-2-text {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 2rem;
        width: 30rem;
        height: 25rem;
        gap: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .cardrequest-2 {
        position: relative;
        height: 100%;
        padding: 2rem 0;
        gap: 2rem;
        flex-direction: column;
    }

    .cardrequest-2-text h3 {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .cardrequest-container {
        height: fit-content;
    }
    
    .cardrequest-2-text {
        width: 80%;
    }
}

@media screen and (max-width: 530px) {
    .cardrequest-2-text {
        width: 90%;
    }
}

@media screen and (max-width: 490px) {
    .cardrequest-2-text {
        width: 90%;
        height: 21.5rem;
        border-radius: 20px;
        padding: 1rem 0.5rem;
    }

    .cardrequest-2-text h3 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
    }

    .cardrequest-2-img {
        width: 95%;
    }

    .cardrequest-2-text ul {
        font-size: 1rem;
    }
}