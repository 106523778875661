.DashboardNavbar {
    width: 100%;
    height: 4rem;
    background: var(--primary-300);
    color: var(--primary-500);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    z-index: 1000;
    position: relative;
}

.DashboardNavbar.sticky {
    position: sticky;
    top: 0;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

.wrapper {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
}

.DashboardNavbar-1 {
    width: 10rem;
    color: var(--primary-300);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
}

.DashboardNavbar-1 img {
    width: 100%;
    height: 3rem;
}

.DashboardNavbar-3 ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    padding-left: 0;
    margin-bottom: 0;
} 

.DashboardNavbar li {
    display: flex;
    justify-content: center;
    align-items: center;
}
.DashboardNavbar-3 ul:hover {
    opacity: 1;
}

.DashboardNavbar button {
    background-color: var(--primary-300);
    color: var(--primary-100);
}

.DashboardNavbar-3 {
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.DashboardNavbar-home {
    height: 100%;
    font-size: 2rem;
    color: var(--primary-400);
    cursor: pointer;
    background: var(--primary-9001);
    padding: 6px;
    border-radius: 50%;
}

.DashboardNavbar-3 .navbar-3-icon {
    margin-right: 1rem;
    height: 100%;
    font-size: 2rem;
    color: var(--primary-500);
    cursor: pointer;
    background: var(--primary-9001);
    padding: 6px;
    border-radius: 50%;
}

.blob {
    margin-right: 0.1rem; 
    transform: scale(0.8);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(0.9);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
.blob2 {
    margin-right: 0.1rem; 
    transform: scale(0.8);
    animation: pulse2 2s infinite;
}

@keyframes pulse2 {
    0% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(0.9);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        width: 100% !important;
    }
}

@media screen and (max-width: 768px) {
}