.advantages-main-container {
    background-color: var(--primary-5001);
}

.advantages {
    width: 80%;
    height: max-content;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
    justify-content: center;
    gap: 10rem;
}

.advantages-1 {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10rem;
    border-right: 2px dashed var(--primary-5001);
    gap: 1rem;
}

.advantages-1 h1 {
    color: var(--primary-5001);
    font-size: 2.5rem;
}

.advantages-1 p {
    font-size: 1.1rem;
    width: 300px;
    font-style: oblique;
}

.advantages-2 {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.advantage-2-1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
}

.advantage-2-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
}

.advantage {
    width: 250px;
    height: 180px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.advantage h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.advantage p {
    font-size: 1rem;
}

.advantage-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-5001);
    color: white;
}

.advantage-icons {
    font-size: 20px;
}

@media screen and (max-width: 1200px) {
    .advantages {
        gap: 4.5rem;
    }

    .advantages-1 {
        padding-right: 4.5rem;
    }
}

@media screen and (max-width: 1000px) {
    .advantages {
        width: 80%;
        flex-direction: column;
        margin: 0 auto;
        gap: 2rem;
    }

    .advantages-1 {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-right: 0;
        border-bottom: 2px dashed var(--primary-5001);
        padding-bottom: 1rem;
    }

    .advantages-1 p {
        font-size: 1.1rem;
        width: 90%;
    }

    .advantages-2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .advantage-2-1 {
        width: 100%;
        justify-content: center;
        gap: 3.5rem;
    }

    .advantage-2-2 {
        width: 100%;
        justify-content: center;
        gap: 3.5rem;
    }
}

@media screen and (max-width: 700px) {
    .advantages {
        width: 90%;
        flex-direction: column;
        margin: 0 auto;
    }

    .advantage-2-1 {
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }

    .advantage-2-2 {
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .advantages-2 {
        gap: 2rem;
    }
    
    .advantage {
        width: 250px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .advantages {
        gap: 1rem;
    }
    
    .advantages-1 h1 {
        color: var(--primary-5001);
        font-size: 2rem;
    }

    .advantages-1 p {
        font-size: 0.95rem;
        font-style: oblique;
    }

    .advantage h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    .advantage p {
        font-size: 0.95rem;
    }

}