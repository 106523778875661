.companyvalues-wrapper {
    width: 100%;
    height: fit-content;
    background-color: var(--primary-5001);
    padding: 2rem 0;
}

.companyvalues {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: fit-content;
    margin: 0 auto;
    gap: 2rem;
}

.companyvalues-1 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.companyvalues-title {
    width: 270px;
    height: 100%;
}

.companyvalues-1 h1 {
    color: var(--primary-1001);
    font-size: 2.5rem;
}

.companyvalues-2 {
    width: 42%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
}

.companyvalue {
    width: 270px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.companyvalues h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: var(--primary-3001);
}

.companyvalues p {
    font-size: 1rem;
    color: var(--primary-1001);
}

.companyvalues-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary-1001);
    color: var(--primary-5001);
}

.companyvalues-icons {
    font-size: 20px;
}

@media screen and (max-width: 1600px) {
    .companyvalues-2 {
        width: 44%;
    }
}

@media screen and (max-width: 1500px) {
    .companyvalues-2 {
        width: 45%;
    }
}

@media screen and (max-width: 1500px) {
    .companyvalues-2 {
        width: 46%;
    }
}

@media screen and (max-width: 1300px) {

    .companyvalues {
        gap: 2.5rem;
        justify-content: center;
    }

    .companyvalues-1 {
        width: fit-content;
        gap: 2.5rem;
    }

    .companyvalues-2 {
        width: 47%;
        gap: 2.5rem;
    }
}

@media screen and (max-width: 1200px) {
    .companyvalues {
        flex-direction: column;
    }

    .companyvalues-1 {
        flex-direction: row;
    }

    .companyvalues-2 {
        width: fit-content;
        flex-direction: row;
    }
}

@media screen and (max-width: 648px) {
    .companyvalue {
        width: 220px;
        height: 100%;
    }

    .companyvalues-title {
        width: 220px;
        height: 100%;
    }
}

@media screen and (max-width: 520px) {
    
    .companyvalues {
        flex-direction: column;
        width: 100%;
    }

    .companyvalues-1 {
        flex-direction: column;
    }

    .companyvalues-2 {
        flex-direction: column;
        align-items: center;
    }

    .companyvalue {
        width: 80%;
        height: 100%;
    }

    .companyvalues-title {
        width: 80%;
        height: 100%;
    }
}