.plans-container {
    display: flex;
    height: fit-content;
    gap: 1.5rem;
    flex-direction: column;
    justify-content: space-between;
}

.plans-1 {
    width: 100%;
    height: fit-content;
}

.plans-1 h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    width: 100%;
    padding: 0.5rem 2rem;
    background: var(--primary-500);
    border-radius: 0 0 10px 10px;
    color: var(--primary-100);
    letter-spacing: 3px;
}

.plans-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.plans-3 h2 {
    color: var(--primary-1001);
    margin-top: 1rem;
    letter-spacing: 5px;
}

.plans-4 {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 30px;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;
}

.plans-4-text {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 20px 20px;
    padding: 1.5rem;
    width: 22rem;
    height: fit-content;
    background-color: var(--primary-300);
    color: var(--primary-100);
    gap: 0.5rem;
    
    transition: all 0.4s ease-in-out;
    align-self: center;
    justify-self: center;
}

.plans-4-text:hover {
    transition: all 0.4s ease-in-out;
    transform: scale(1.05);
}

.plans-4-text h3 {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 3px;
    line-height: 2.2rem;
    font-weight: 600;
    background-color: var(--primary-100);
    color: var(--primary-400);
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    -webkit-box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
    -moz-box-shadow: 0px 0px 15px 5px rgba(179, 202, 255, 0.8);
}

.plans-4-text ul {
    font-size: 1rem;
    line-height: 2rem;
}

.plans-4-text li {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
}

.plans-4-icon {
    font-size: 1.3rem;
    color: var(--primary-100);
}

.plans-4-text ul li span {
    font-weight: 500;
    margin-left: 5px;
}

.plans-4-text button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: fit-content;
    background-color: var(--primary-100);
    color: var(--primary-300);
}

.plans-4-text button:hover {
    transform: scale(0.98);
    background-color: var(--primary-100);
    color: var(--primary-300);
}

.plans-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 80%;
    height: max-content;
    background-color: var(--primary-300);
    border-radius: 10px;
    padding: 1rem;
    color: var(--primary-100);
    margin: 0 auto;
    margin-bottom: 2rem;
    transition: .3s;
    box-shadow: 1px 1px 2px 3px rgba(22, 34, 51, 0.08);
}

.plans-section h5 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
}

.plans-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4rem;
    cursor: pointer;
}

.withdraw-icon {
    font-size: 2rem;
    color: var(--primary-100);
    transition: .3s;
}

.plans-section h2 {
    margin-bottom: 1.2rem;
}

.plans-section p {
    margin-bottom: 1.2rem;
}

.plans-section .toggle-menu {
    height: fit-content;
    display: none;
    transition: .3s;
}

.plans-section .toggle-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-100);
    padding: 1rem 0;
    border-radius: 10px;
}

.toggle-menu input,
.toggle-menu textarea,
.toggle-menu input:active{
    outline: none;
}

.toggle-menu form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.toggle-menu input {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    border-radius: 10px;
}

.toggle-menu input::placeholder {
    color: var(--primary-700);
}

.toggle-menu label {
    color: var(--primary-300);
    display: block;
    margin-top: 1rem;
}

.toggle-menu button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    border: none;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--primary-400);
    color: var(--primary-100);
    transition: all 0.4s ease-in;
}

@media screen and (max-width: 1400px) {
    .plans-2-1 {
        width: 100%;
    }

    .plans-3-1,
    .plans-3-2 {
        width: 80%;
    }

    .plans-4 {
        width: 80%;
    }

    .plansbalance2 {
        width: 100%;
    }

    .plans-section {
        width: 80%;
    }
}

@media screen and (max-width: 1050px) {
    .plans-2-1 {
        width: 100%;
    }

    .plans-3-1,
    .plans-3-2 {
        width: 90%;
    }

    .plansbalance2 {
        height: 23rem;
    }

    .plans-4 {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
    }

    .plansbalance2 {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {

    .plans-2 {
        padding: 1rem;
    }

    .plans-section {
        width: 100%;
    }

    .plans-section h5 {
        font-size: 1rem;
    }

    .withdraw-icon {
        font-size: 1.5rem;
    }

    .toggle-menu input {
        border: 3px solid var(--primary-700);
        color: var(--primary-300);
        padding: 0 1rem;
        width: 95%;
    }

    .toggle-menu input::placeholder {
        color: var(--primary-700);
    }

    .toggle-menu label {
        color: var(--primary-300);
        display: block;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .plans-2-1 h2 {
        font-size: 2rem;
    }
}
