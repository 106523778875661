.withdrawal-history-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 65%;
    height: max-content;
    background-color: var(--primary-300);
    color: var(--primary-100);
    margin: 0 auto;
    margin-bottom: 2rem;
    border-radius: 10px;
    transition: .3s;
    padding-top: 0.3rem;
    box-shadow: 1px 1px 2px 3px rgba(22, 34, 51, 0.08);
}

.withdrawal-history-section h5 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
}

.history-dashboards-3-icon {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    color: var(--primary-500);
}

.withdrawal-history-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;
    cursor: pointer;
}

.withdraw-history-icon {
    font-size: 1.5rem;
    color: var(--primary-100);
    transition: .3s;
}

.withdrawal-history-section h2 {
    margin-bottom: 1.2rem;
}

.withdrawal-history-section p {
    margin-bottom: 1.2rem;
}

.withdrawal-history-section .toggle-menu {
    height: fit-content;
    display: none;
    transition: .3s;
}

.withdrawal-history-section .toggle-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
    background-color: #533c92;
    padding: 1rem 0;
}

.toggle-menu-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: solid 0.3rem var(--primary-1001);
}

.toggle-menu-text {
    padding-bottom: 0.2rem;
    color: var(--primary-100);
}

.toggle-menu-progress {
    position: relative;
    display: flex;
    padding-left: 2rem;
    width: 80%;
}

.menu.dots{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: aqua;
}

@media screen and (max-width: 1400px) {
    .withdrawal-history-section {
        width: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .withdrawal-history-section-1 {
        gap: 3rem;
    }
}

@media screen and (max-width: 850px) {

    .withdrawal-history-section {
        width: 100%;
    }

    .withdrawal-history-section h5 {
        font-size: 1rem;
    }
}