.topup-container {
    display: flex;
    height: fit-content;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.topup-1 {
    width: 100%;
    height: fit-content;
}

.topup-1 h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    width: 100%;
    padding: 0.5rem 2rem;
    background: var(--primary-500);
    border-radius: 0 0 10px 10px;
    color: var(--primary-100);
    letter-spacing: 3px;
}

.topup-2 {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 0 auto;
}

.topup-2-1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.topup-2-1-1 p {
    background-color: var(--primary-300);
    color: var(--primary-100);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    margin-bottom: 0 !important;
}

.topup-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    height: max-content;
    background-color: var(--primary-300);
    padding: 1rem;
    color: var(--primary-100);
    margin: 0 auto;
    margin-bottom: 2rem;
    transition: .3s;
    box-shadow: 1px 1px 2px 3px rgba(22, 34, 51, 0.08);
}

.topup-section h5 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
}

.topup-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4rem;
    cursor: pointer;
}

.withdraw-icon {
    font-size: 2rem;
    color: var(--primary-100);
    transition: .3s;
}

.topup-section h2 {
    margin-bottom: 1.2rem;
}

.topup-section p {
    margin-bottom: 1.2rem;
}

.topup-section .toggle-menu {
    height: fit-content;
    display: none;
    transition: .3s;
}

.topup-section .toggle-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-100);
    padding: 1rem 0;
}

.toggle-menu input,
.toggle-menu textarea,
.toggle-menu select,
.toggle-menu input:active,
.toggle-menu select:active {
    outline: none;
}

.toggle-menu form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.toggle-menu input {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    border-radius: 10px;
}

.toggle-menu input::placeholder,
.toggle-menu textarea::placeholder {
    color: var(--primary-700);
}

.toggle-menu label {
    color: var(--primary-300);
    display: block;
    margin-top: 1rem;
}

.toggle-menu button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    border: none;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--primary-500);
    color: var(--primary-100);
    transition: all 0.4s ease-in;
}

.topup-btn:active {
    transform: scale(0.98);
}

.coupon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--primary-700);
    color: var(--primary-500);
    background-color: var(--primary-700);
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    border-radius: 10px;
    margin-top: 1rem;
}

@media screen and (max-width: 1300px) {
    .topup-2-1 {
        width: 100%;
    }

}

@media screen and (max-width: 1000px) {
    .topup-section-1 {
        gap: 3rem;
    }

    .topup-section {
        width: 100%;
        max-width: 800px;
    }
}

@media screen and (max-width: 850px) {
    .topup-2 {
        padding: 1rem;
    }

    .topup-2-1 {
        width: 100%;
    }

    .topup-section {
        width: 100%;
    }

    .topup-section h5 {
        font-size: 1rem;
    }

    .withdraw-icon {
        font-size: 1.5rem;
    }

    .topup-section-1 {
        gap: 2rem;
    }

    .toggle-menu input {
        border: 3px solid var(--primary-700);
        color: var(--primary-300);
        padding: 0 1rem;
        width: 95%;
    }

    .toggle-menu input::placeholder {
        color: var(--primary-700);
    }

    .toggle-menu label {
        color: var(--primary-300);
        display: block;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 460px) {
    .topup-section-1 {
        gap: 1rem;
    }

    .topup-section h5 {
        font-size: 0.9rem;
    }
}
