.withdrawrequest-container {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.withdrawrequest-1 {
    width: 100%;
    height: fit-content;
}

.withdrawrequest-1 h4 {
    font-size: 1.5rem;
    width: 100%;
    padding: 0.5rem 2rem;
    background: var(--primary-500);
    border-radius: 0 0 10px 10px;
    color: var(--primary-100);
    letter-spacing: 3px;
}

.withdrawrequest-2 {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    flex-direction: column;
    padding: 0 1rem;
}

.withdrawrequest-2-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.withdrawrequest-2-1 h3 {
    font-weight: 700;
    font-size: 1.3rem;
}

.withdrawrequest-2-1 p {
    background-color: var(--primary-300);
    color: var(--primary-100);
    padding: 1rem;
    font-size: 1rem;
    border-radius: 10px;
}

.withdrawrequest-2-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.withdrawrequest-2-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
}

.withdrawrequest-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.withdrawrequest-heading .withdrawrequest-icon-container {
    color: var(--primary-300);
    background-color: var(--primary-100);
    padding: 0.4rem;
    height: fit-content;
    width: fit-content;
    border-radius: 30px 30px 0 0;
}

.withdrawrequest-heading .withdrawrequest-icon {
    font-size: 2rem;
}

.withdrawrequest-heading h2 {
    color: var(--primary-100);
    background-color: var(--primary-300);
    font-size: 1.2rem;
    border: 3px solid var(--primary-100);
    border-radius: 10px;
    width: fit-content;
    padding: 0.3rem 1rem;
    margin: 0 auto;
}

.withdrawrequest-details {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    margin: 1rem 0;
    background-color: var(--primary-100);
    gap: 0.5rem;
    border-radius: 10px 25px 10px 10px;
}

.withdrawrequest-details h5 {
    text-align: left;
    width: 100%;
    font-size: 0.9rem;
    color: var(--primary-200);
    padding-bottom: 2px;
    border-bottom: 1.5px solid var(--primary-700);
}

.withdrawrequest-details h5 span {
    color: var(--primary-300);
    font-size: 1rem;
    display: block;
    word-wrap: break-word;
}

.withdrawrequest-details-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-500);
    right: -1%;
    top: -1%;
}

.withdrawrequest-details-circle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-300);
    border: 2px dotted var(--primary-400);
}

.line-divider {
    width: 100%;
    height: 2px;
    background-color: var(--primary-800);
}

.error {
    color: var(--primary-100);
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
}

.withdrawrequest-2-2 form {
    width: 100%;
    text-align: center;
    max-width: 700px;
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 10px;
    background-color: var(--primary-300);
}

.withdrawrequest-2-2 form label {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    display: block;
    text-align: left;
    margin: 1rem 0 0.2rem;
    color: var(--primary-100);
}

.withdrawrequest-2-2 input {
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    color: var(--primary-300);
    border: none;
    border-radius: 5px;
    border-bottom: 2px solid var(--primary-700);
    background-color: var(--primary-100);
    outline: none;
}

.copy-icon-admin {
    display: inline;
    margin-left: 10px;
}

.withdrawrequest-2-2 textarea {
    border: none;
    color: var(--primary-300);
    width: 100%;
    height: 5rem;
    border-radius: 10px;
    resize: none;
    padding: 3px;
    outline: none;
}

.withdrawrequest-2-2 select {
    background-color: var(--primary-300);
    color: var(--primary-100);
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
    align-self: center;
}

.label-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdrawrequest-2-2 input:focus,
.withdrawrequest-2-2 select:focus
.withdrawrequest-2-2 textarea:focus {
    border-color: var(--primary-500);
}

.withdrawrequest-2-2 input::placeholder,
.withdrawrequest-2-2 select::placeholder,
.withdrawrequest-2-2 textarea::placeholder {
    color: var(--primary-700);
}

.withdrawrequest-2-2 textarea:active {
    outline: none;
}

.withdrawrequest-2-2 button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    border: 3px solid var(--primary-100);
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.withdrawrequest-2-2 button:hover {
    transform: scale(0.98);
}

.withdrawrequest-2-2 input.input-error,
.withdrawrequest-2-2 select.input-error {
    border-color: #fc8181;
}

.button-contianer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.add-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color: var(--primary-200);
    color: var(--primary-100);
    border-radius: 0 0 10px 10px;
    width: 100%;
}

.text-container {
    height: fit-content;
    display: none;
    transition: .3s;
}

.withdrawrequest-2-2 .text-container.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .withdrawrequest-container {
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .withdrawrequest-2-2 form {
        width: 95%;
    }

    .withdrawrequest-2-2 p {
        font-size: 0.83rem;
    }
}

