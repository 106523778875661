.dashboard-container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.dashboard-container-11 {
    display: flex;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 3;
}

.dashboard-container-1 {
    width: fit-content;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.dashboard-container-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-top: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100vh;
    width: 100%;
}

.dashboard-background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
}

.dashboard-background-img {
    position: relative;
    width: 100%;
    height: 100%;
}

#tsparticles canvas {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    position: absolute !important;
    z-index: 5;
}

.dashboard-sidebar {
    height: 100vh;
    width: 200px;
    transition: width 0.5s;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.dashboard-top_section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    height: 2rem;
}

.bars-icon {
    position: absolute;
    right: -15px;
    top: 13%;
    font-size: 30px;
    z-index: 100;
    color: var(--primary-1001);
    padding: 5px;
    border-radius: 5px;
    background-color: var(--primary-500);
    transition: all 0.5s;
}

.dashboard-link {
    display: flex;
    align-items: center;
    color: var(--primary-100);
    padding: 10px 10px;
    margin-bottom: 0.5rem;
    gap: 15px;
    transition: all 0.5s;
}

.dashboard-link:hover {
    background: var(--primary-5002);
    color: var(--primary-100);
    transition: all 0.3s;
    border-radius: 10px;
}

.dashboard-link.active {
    background: var(--primary-5002);
    color: var(--primary-100);
    border-left: 3px solid var(--primary-300);
    border-radius: 10px;
}

.dashboard-icon {
    color: var(--primary-400);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.dashboard-link_text {
    color: var(--primary-1001);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}

.dashboard-container-2::-webkit-scrollbar {
    display: none;
}

.dashboard-btn {
    font-family: 'Montserrat Alternates', sans-serif;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    background-color: var(--primary-5001) ;
    color: var(--primary-1001);
    border-radius: 40px;
    height: 2rem;
    font-size: 0.5rem;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
}

.dashboard-btn:hover {
    transform: scale(0.98);
}

.logout-icon {
    font-size: 25px;
}

.sidebar-close {
    display: none;
    font-size: 2.5rem;
    color: var(--primary-1001);
    width: 100%;
}

.sidebar-open {
    display: none;
    font-size: 2rem;
    color: var(--primary-500);
}

.dashboard-sidebar button {
    background-color: var(--primary-100);
    color: var(--primary-300);
}

@media screen and (max-width: 768px) {
    .dashboard-container-1 {
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        transform: translateX(-110%);
        transition: all .45s;
        z-index: 200;
        background-color: rgba(0, 0, 0);
    }

    .dashboard-sidebar { 
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 200px !important;
        height: 100vh;
    }

    .dashboard-container-1.active {
        transform: translateX(0%);
    }
    
    .sidebar-close, .sidebar-open {
        display: block;
    }

    .dashboard-top_section {
        padding-top: 2.3rem;
        position: static;
    }

    .bars-icon {
        display: none;
    }

    .sidebar-open {
        top: -3rem;
        right: 8.5rem;
        z-index: 100;
    }
}