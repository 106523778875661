.coin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  width: 90vw; 
  height: fit-content;
  justify-content: space-between;
}

.coin-containers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 900px;
  width: 90vw;
  border: 2px solid var(--primary-500);
  padding: 0 1rem
}

.coin-row {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.coin-img {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
}

.coin-img img {
  width: 100%;
  margin-right: 24px;
}

.coin-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 150px;
  color: var(--primary-700);
}

.coin-name h5 {
  font-size: 1.2rem;
  font-weight: 600;
}

.coin-symbol {
  font-weight: 300;
  text-transform: uppercase;
}

.coin-data {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.coin-price {
  width: 140px;
  color: var(--primary-700);
}

.coin-percent {
  width: 100px;
}

.coin-percent.red {
  color: #f00606;
}

.coin-percent.green {
  color: #11d811;
} 

.coin-icon {
  color: var(--primary-500);
  font-size: 1.5rem;
  cursor: pointer;
}

.coin-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  height: max-content;
  background-color: var(--primary-500);
  padding: 1rem;
  color: var(--primary-1001);
  border-radius: 0 0 10px 10px;
  margin: 0 auto;
  margin-bottom: 2rem;
  transition: .3s;
}

.coin-section h5 {
  text-align: center;
  margin-bottom: 0;
  font-weight: 700;
}

.coin-section-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4rem;
  cursor: pointer;
}

.withdraw-icon {
  font-size: 2rem;
  color: var(--primary-100);
  transition: .3s;
}

.coin-section h2 {
  margin-bottom: 1.2rem;
}

.coin-section p {
  margin-bottom: 1.2rem;
}

.coin-section .toggle-menu {
  height: fit-content;
  display: none;
  transition: .3s;
}

.coin-section .toggle-menu.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  background-color: var(--primary-100);
  padding: 1rem 0;
  border-radius: 10px;
}

.toggle-menu input,
.toggle-menu textarea,
.toggle-menu select,
.toggle-menu input:active,
.toggle-menu select:active {
  outline: none;
}

.toggle-menu form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.toggle-menu input {
  border: 3px solid var(--primary-700);
  color: var(--primary-300);
  padding: 0 1rem;
  width: 90%;
  height: 3rem;
  border-radius: 10px;
}

.toggle-menu input::placeholder,
.toggle-menu textarea::placeholder {
  color: var(--primary-700);
}

.toggle-menu label {
  color: var(--primary-300);
  display: block;
  margin-top: 1rem;
}

.toggle-menu button {
  font-family: 'Montserrat Alternates', sans-serif;
  display: block;
  margin: 1rem auto 1rem;
  padding: 0.5rem 0.1rem;
  border: none;
  border-radius: 40px;
  width: 7rem;
  height: 3rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--primary-500);
  color: var(--primary-100);
  transition: all 0.4s ease-in;
}

.coin-btn:active {
  transform: scale(0.98);
}

.coin-amount-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--primary-700);
  color: var(--primary-500);
  background-color: var(--primary-700);
  padding: 0 1rem;
  width: 90%;
  height: 3rem;
  border-radius: 10px;
}

@media screen and (max-width: 1300px) {
  .coin-2-1 {
    width: 100%;
  }

}

@media screen and (max-width: 1000px) {
  .coin-section-1 {
    gap: 3rem;
  }

  .coin-section {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .coin-2 {
    padding: 1rem;
  }

  .coin-2-1 {
    width: 100%;
  }

  .coin-section {
    width: 100%;
  }

  .coin-section h5 {
    font-size: 1rem;
  }

  .withdraw-icon {
    font-size: 1.5rem;
  }

  .coin-section-1 {
    gap: 2rem;
  }

  .toggle-menu input {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    padding: 0 1rem;
    width: 95%;
  }

  .toggle-menu input::placeholder {
    color: var(--primary-700);
  }

  .toggle-menu label {
    color: var(--primary-300);
    display: block;
    margin-top: 1rem;
  }

  .coin-row {
    height: 70px;
    gap: 1rem;
  }

  .coin-data {
    gap: 0;
  }

  .coin-name {
    width: 100px;
  }
  .coin-price {
    width: 100px;
  }

  .coin-percent {
    width: 60px;
  }

}

@media screen and (max-width: 460px) {
  .coin-section-1 {
    gap: 1rem;
  }

  .coin-section h5 {
    font-size: 0.9rem;
  }

  .coin-containers {
    padding: 0 0.3rem;
  }

  .coin-row {
    gap: 0.5rem;
  }

  .coin-name h5 {
    font-size: 1rem;
  }
  
}
