.hero-wrapper {
    width: 100%;
    background-color: var(--primary-5001);
    border-top: solid 2px var(--primary-2001);
    position: relative;
}

.hero {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 80%;
    height: 37rem;
}

.hero-1{
    width: 50%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.hero-1 h3 {
    display: flex;
    align-items: center;
    font-size: 1rem;
    justify-content: center;
    width: 350px;
    height: 30px;
    font-family: 'Poppins', sans-serif !important;
    background-color: var(--primary-3001);
    color: var(--primary-1001);
    border-radius: 100px;
    text-align: center;
    letter-spacing: 1px;
}

.hero-1 h1 {
    color: var(--primary-1001);
    font-size: 3.5rem;
    letter-spacing: 5px;
}

.hero-1 p {
    color: var(--primary-1001);
    font-size: 1rem;
    width: 70%;
}

.download-btns {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.download-btn {
    width: 12rem;
    height: 3rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

.download-btn:hover {
    transform: scale(0.98);
    transition: all 0.5s;
}

.download-btn img {
    width: 100%;
}

.hero-2 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-2 img {
    width: 100%;
}

.tsparticles-hero {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
}

@media screen and (max-width: 1432px) {
    .hero-1 h1 {
        color: var(--primary-1001);
        font-size: 2.5rem;
        letter-spacing: 5px;
    }

    .hero-1 p {
        color: var(--primary-1001);
        font-size: 1rem;
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .hero-wrapper {
        width: 100%;
        background-color: var(--primary-5001);
        padding-bottom: 1rem;
        border-top: solid 2px var(--primary-2001);
    }

    .hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        width: 80%;
        height: fit-content;
        gap: 3rem;
    }

    .hero-1{
        width: 100%;
    }

    .hero-1 h3 {
        margin: 0 auto;
    }

    .hero-1 h1 {
        color: var(--primary-1001);
        font-size: 3rem;
    }

    .hero-1 p {
        color: var(--primary-1001);
        font-size: 1rem;
        width: 70%;
        margin: 0 auto;
    }

    .download-btns {
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }

    .hero-2 {
        width: 60%;
    }

    .hero-2 img {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .hero-2 {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .hero-2 {
        width: 75%;
    }

    .hero-1 h1 {
        font-size: 2.5rem;
    }

    .hero-1 p {
        width: 80%;
    }

}

@media screen and (max-width: 550px) {
    .hero {
        width: 90%;
    }
    
    .hero-1 h1 {
        font-size: 2rem;
    }

    .hero-1 p {
        width: 90%;
    }
}
