.withdrawal-container {
    display: flex;
    height: fit-content;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.withdrawal-1 {
    width: 100%;
    height: fit-content;
}

.withdrawal-2 {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 0 auto;
}

.withdrawal-2-1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.withdrawalbalance2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: row;
    margin: 1rem auto;
    width: 80%;
    height: 15rem;
    background-color: var(--primary-300);
    border-radius: 10px;
    filter: drop-shadow(12px 7px 39px rgba(61, 89, 154, 0.58));
}

.withdrawalbalance2 p {
    font-weight: 600;
}

.withdrawalbalance2-1 {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: column;
    margin: 1rem auto;
    border-radius: 10px;
    width: 95%;
    height: 80%;
    background-color: var(--primary-100);
    gap: 0.5rem;
}

.withdrawalbalance2-1-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    border-bottom: 2px ridge var(--primary-700);
}

.withdrawalbalance2-1-1 p {
    color: var(--primary-200);
}

.withdrawalbalance2-1-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.withdrawalbalance2-1-iconcontainer {
    padding: 0.6rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    border-radius: 50%;
}

.withdrawalbalance2-1-icon {
    font-size: 1.5rem;
}

.withdrawalbalance2 h2 {
    font-size: 3rem;
    letter-spacing: 2px;
    color: var(--primary-200);
}

.verified-kyc-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.verified-kyc {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
}

.verified-kyc p {
    background-color: var(--primary-300);
    color: var(--primary-100);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.withdrawal-2-1-icon {
    font-size: 1.1rem;
    color: var(--primary-600);
}

.withdrawal-2-1-icon2 {
    font-size: 1.1rem;
    color: var(--primary-905);
}

.withdrawal-3 {
    color: var(--primary-1001);
}

.kyc-link {
    background-color: var(--primary-500);
    color: var(--primary-100);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    margin-bottom: 0 !important;
    cursor: pointer;
}

.kyc-link:hover {
    transition: all 0.4s ease-in-out;
    transform: scaleX(0.98);
    color: var(--primary-700);
}

.savedBanks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: 90%;
    height: 3rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    padding: 2rem 1rem;
    border-radius: 10px;
    /* -webkit-box-shadow: 0px 10px 13px 7px #000000, 5px 5px 15px 5px rgba(255, 255, 255, 0); */
    box-shadow: 0px 15px 13px -9px #000000, 5px 5px 15px 5px rgba(255, 255, 255, 0);
}

.savedBanks-header {
    color: var(--primary-300);
}

.savedBanks-1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.savedBanks-2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.3rem;
}

.savedBanks-2 h5 {
    font-size: 1rem;
}

.savedBanks-2 p {
    margin-bottom: 0 !important;
}

.withdrawal-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 80%;
    height: max-content;
    background-color: var(--primary-300);
    padding: 1rem;
    color: var(--primary-100);
    margin: 0 auto;
    margin-bottom: 2rem;
    border-radius: 10px;
    transition: .3s;
    box-shadow: 1px 1px 2px 3px rgba(22, 34, 51, 0.08);
}

.withdrawal-section h5 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
}

.withdrawal-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4rem;
    cursor: pointer;
}

.withdraw-icon {
    font-size: 2rem; 
    color: var(--primary-100);
    transition: .3s;
}

.withdrawal-section h2 {
    margin-bottom: 1.2rem;
}

.withdrawal-section p {
    margin-bottom: 1.2rem;
}

.withdrawal-section .toggle-menu {
    height: fit-content;
    display: none;
    transition: .3s;
}

.withdrawal-section .toggle-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-100);
    padding: 1rem 0;
}

.toggle-menu input, .toggle-menu textarea, .toggle-menu select, .toggle-menu input:active, .toggle-menu textarea:active, .toggle-menu select:active {
    outline: none;
}

.toggle-menu form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.bankForm {
    border-top: 4px solid var(--primary-700);
    margin-top: 1rem;
}

.toggle-menu input {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    border-radius: 10px;
}

.toggle-menu input::placeholder, .toggle-menu textarea::placeholder {
    color: var(--primary-700);
}

.toggle-menu label {
    color: var(--primary-300);
    display: block;
    margin-top: 1rem;
}

.toggle-menu textarea {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    width: 90%;
    height: 5rem;
    border-radius: 10px;
    resize: none;
    padding: 3px;
    margin-top: 1rem;
}

.toggle-menu select {
    background-color: var(--primary-300);
    color: var(--primary-100);
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
}

.toggle-menu button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    border: none;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--primary-500);
    color: var(--primary-100);
    transition: all 0.4s ease-in;
}

.expiry-input {
    display: inline !important;
    width: 80px !important;
}

.cvv-input {
    display: inline !important;
    width: 80px !important;
}

.expiry-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.withdrawal-btn:active {
    transform: scale(0.98);
}

.withdrawal-3 {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.withdrawal-2-1-h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    width: 65%;
    text-align: left;
    letter-spacing: 3px;
    font-size: 2.3rem;
    color: var(--primary-100);
    background-color: var(--primary-300);
    padding: 0.5rem 0.5rem 0;
    border-bottom: 2px solid var(--primary-100);
    border-top: 2px solid var(--primary-100);
}

.withdrawals-3-main {
    width: 65%;
    padding: 1.5rem;
    background-color: var(--primary-800);
    border-radius: 0 0 10px 10px;
}

@media screen and (max-width: 1400px) {
    .withdrawal-2-1 {
        width: 100%;
    }
    .withdrawalbalance2 {
        width: 100%;
    }

    .withdrawal-section {
        width: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .withdrawal-section-1 {
        gap: 3rem;
    }

    .withdrawalbalance2 {
        height: 13rem;
    }

    .withdrawal-2-1-h2 {
        width: 100%;
    }

    .withdrawals-3-main {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    .withdrawal-2 {
        padding: 1rem;
    }

    .verified-kyc {
        margin-top: 1.4rem;
        gap: 1rem;
    }

    .withdrawal-section {
        width: 100%;
    }

    .withdrawal-section h5 {
        font-size: 1rem;
    }

    .withdraw-icon {
        font-size: 1.5rem;
    }

    .withdrawal-section-1 {
        gap: 2rem;
    }

    .toggle-menu input {
        border: 3px solid var(--primary-700);
        color: var(--primary-300);
        padding: 0 1rem;
        width: 95%;
    }

    .toggle-menu input::placeholder {
        color: var(--primary-700);
    }

    .toggle-menu label {
        color: var(--primary-300);
        display: block;
        margin-top: 1rem;
    }

    .toggle-menu textarea {
        width: 95%;
        height: 7rem;
    }

    .verified-kyc-container {
        flex-direction: column;
        gap: 0;
    }
}

@media screen and (max-width: 450px) {
    .withdrawal-2-1 h2 {
        font-size: 2rem;
    }
}