.LoginNavbar {
    width: 100%;
    height: 4rem;
    background: var(--primary-3001);
    color: var(--primary-5001);
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.LoginNavbar.sticky {
    position: sticky;
    top: 0;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

.wrapper {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
}

.LoginNavbar-1 {
    width: 10rem;
    color: var(--primary-3001);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
}

.LoginNavbar-1 img {
    width: 100%;
    height: 3rem;
}

.LoginNavbar-3 ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    padding-left: 0;
    margin-bottom: 0;
} 

.LoginNavbar li {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginNavbar-3 ul:hover {
    opacity: 1;
}

.LoginNavbar button {
    background-color: var(--primary-3001);
    color: var(--primary-1001);
}

.LoginNavbar-3 {
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.LoginNavbar-home {
    height: 100%;
    font-size: 2rem;
    color: var(--primary-5001);
    cursor: pointer;
    background: var(--primary-9001);
    padding: 6px;
    border-radius: 50%;
    margin-right: 1rem;
}