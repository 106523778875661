.deposit-container {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.deposit-1 {
    width: 100%;
    height: fit-content;
}

.depositbalance {
    color: var(--primary-1001);
}

.deposit-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    height: max-content;
    background-color: var(--primary-300);
    padding: 1rem;
    color: var(--primary-300);
    margin: 0 auto;
    margin-bottom: 2rem;
    border-radius: 10px;
    transition: .3s;
    box-shadow: 1px 1px 2px 3px rgba(22, 34, 51, 0.08);
}

.deposit-section h5 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
    color: var(--primary-100);
}

.deposit-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4rem;
    cursor: pointer;
}

.withdraw-icon {
    font-size: 2rem;
    color: var(--primary-100);
    transition: .3s;
}

.deposit-section h2 {
    margin-bottom: 1.2rem;
}

.deposit-section p {
    margin-bottom: 1.2rem;
}

.deposit-section .toggle-menu {
    height: fit-content;
    display: none;
    transition: .3s;
}

.deposit-section .toggle-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-100);
    padding: 1rem 0;
}

.deposit-2 {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    flex-direction: column;
    padding: 0 1rem;
}

.deposit-2-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deposit-2-1 h3 {
    font-weight: 700;
    font-size: 1.3rem;
}

.deposit-2-1 p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    text-decoration: underline dotted;
}

.deposit-2-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deposit-2-2 h4 {
    color: var(--primary-100);
    background-color: var(--primary-300);
    border-radius: 5px;
    padding: 0.5rem 1rem;
}

.deposit-2-2 .copy-icon {
    color: var(--primary-500);
    font-size: 1.5rem;
    cursor: pointer;
}

.deposit-2-2 .copy-icon:hover {
    transform: scale(1.1);
}

.deposit-img {
    margin: 0.5rem 0;
    width: 20rem;
    height: 20rem;
}

.deposit-img img {
    width: 100%;
    height: auto;
}

.deposit-2-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    padding: 0 2rem;
}

.btclogo-img {
    width: 4rem;
    height: 4rem;
}

.btclogo-img img {
    width: 100%;
}

.address-text {
    font-size: 0.8rem;
}

.create-project {
    color: var(--primary-100);
    background: var(--primary-300)
}

.notify-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
    flex-direction: column;
    gap: 0.5rem;
    width: 7rem;
    height: 4rem;
    background-color: var(--primary-500);
    color: var(--primary-1001);
    transition: 0.3s ease-in;
    font-size: 0.85rem;
}

.notify-link-icon {
    font-size: 1.3rem;
    animation: test 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}

@keyframes test {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(5px, 2px, 1px);
    }
}


@media screen and (max-width: 850px) {
    .deposit-section {
        width: 100%;
        padding: 0.5rem;
    }

    .deposit-section h5 {
        font-size: 1rem;
    }

    .withdraw-icon {
        font-size: 1.5rem;
    }

    .deposit-section-1 {
        gap: 2rem;
    }

    .deposit-section-1 {
        gap: 2rem;
    }
}

@media screen and (max-width: 605px) {
    .deposit-container {
        height: fit-content;
        gap: 1rem;
    }

    .deposit-2 {
        width: 100%;
        gap: 1rem;
    }

    .deposit-2-1 h3 {
        text-align: center;
        font-weight: 700;
        font-size: 0.9rem;
    }

    .deposit-2-1 p {
        font-size: 0.9rem;
    }

    .deposit-2-2 .copy-icon:hover {
        transform: scale(1.1);
    }

    .deposit-img {
        width: 20rem;
        height: 20rem;
    }

    .deposit-img img {
        width: 100%;
        height: auto;
    }

    .deposit-2-3 {
        justify-content: center;
        gap: 1rem;
        padding: 0;
    }

    .btclogo-img {
        width: 3.5rem;
        height: 4rem;
    }

    .deposit-2-3 h5 {
        font-size: 1rem;
    }
    
    .deposit-2-3 p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 480px) {
    .deposit-2-3 {
        justify-content: space-between;
        gap: 0;
        padding: 0;
    }
}

@media screen and (max-width: 450px) {
    .deposit-section p {
        font-size: 0.85rem;
    }
}