.kyc-container {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.kyc-1 {
    width: 100%;
    height: fit-content;
}

.kyc-2 {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    flex-direction: column;
    padding: 0 1rem;
}

.kyc-2-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.kyc-2-1 h3 {
    color: var(--primary-1001);
    font-weight: 700;
    font-size: 1.3rem;
}

.kyc-2-1-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    border-radius: 10px;
}

.kyc-2-1-icon {
    font-size: 1.5rem;
    color: var(--primary-600);
}

.kyc-2-1-icon2 {
    font-size: 1.5rem;
    color: var(--primary-905);
}

.kyc-2-1 p {
    font-size: 1rem;
    border-radius: 10px;
}

.kyc-2-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kyc-2-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
}

.kyc-heading p {
    color: var(--primary-100);
}

.error {
    color: var(--primary-100);
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
}

.kyc-2-2 form {
    width: 100%;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
    background-color: var(--primary-300);
}

.kyc-2-2 form label {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    display: block;
    text-align: left;
    margin: 1rem 0 0.2rem;
    color: var(--primary-100);
}

.kyc-2-2 input {
    width: 100%;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    color: var(--primary-300);
    border: none;
    border-radius: 5px;
    border-bottom: 2px solid var(--primary-700);
    background-color: var(--primary-100);
    outline: none;
}

.kyc-2-2 input:focus,
.kyc-2-2 select:focus {
    border-color: var(--primary-500);
}

.kyc-2-2 input::placeholder,
.kyc-2-2 select::placeholder {
    color: var(--primary-700);
}

.kyc-2-2 button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    border: 3px solid var(--primary-100);
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.kyc-2-2 button:hover {
    transform: scale(0.98);
}

.kyc-2-2 input.input-error,
.kyc-2-2 select.input-error {
    border-color: #fc8181;
}

@media screen and (max-width: 1200px) {
    .kyc-container {
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .kyc-2-2 form {
        width: 95%;
    }

    .kyc-2-2 p {
        font-size: 0.83rem;
    }
}

@media screen and (max-width: 450px) {
    .kyc-2-1 h3 {
        font-size: 1rem;
    }

    .kyc-2-1-1 {
        padding: 0.5rem 0.9rem;
    }

    .kyc-2-1 p {
        font-size: 0.9rem;
    }
}

