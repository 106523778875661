:root {
    --swiper-navigation-size: 40px !important;
    --swiper-theme-color: rgba(0, 102, 255, 1) !important;
}

.partner-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem;
}

.partner-title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 67px;
    letter-spacing: 0.4rem;
    text-align: center;
    padding-bottom: 0.5rem;
    width: 90%;
}

.partner-img {
    width: 100px;
}

.partner-img img {
    width: 100%;
}

.mySwipers {
    width: 100%;
}

.partner-swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    width: 100%;
    padding: 0 4rem;
}

/* Image as Grayscale */

.partner-img img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.partner-img img:hover {
    filter: none;
    -webkit-filter: grayscale(0);
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1200px) {
    .mySwipers {
        width: 70%;
    }

    .partner-swiper {
        gap: 2rem;
        padding: 0 2rem;
    }
}

@media screen and (max-width: 870px) {
    .mySwipers {
        width: 80%;
    }

    .partner-swiper {
        gap: 2rem;
        padding: 0 2rem;
    }
}

@media screen and (max-width: 660px) {
    .mySwipers {
        width: 95%;
    }

    .partner-swiper {
        margin: 0 auto;
        gap: 0.5rem;
        padding: 0 1rem;
    }

    .partner-title {
        font-size: 1.5rem;
        line-height: 50px;
    }
}

@media screen and (max-width: 440px) {
    .mySwipers {
        width: 95%;
    }
}