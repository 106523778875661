@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500;600;700;800;900&family=Montserrat:wght@400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat+Alternates:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --primary-1001: #ffffff;
    --primary-2001: #004035;
    --primary-3001: #000000;
    --primary-4001: rgb(179, 202, 255);
    --primary-5001: rgb(38, 132, 254);
    --primary-6001: rgb(250, 139, 13);
    --primary-7001: rgb(230, 232, 235);
    --primary-8001: rgba(230, 232, 235, 0.2);
    --primary-9001: rgba(230, 232, 235, 0.2);
    --primary-9051: rgba(3, 218, 85);
    --primary-5002: #1F0D4C;

    --primary-100: #ffffff;
    --primary-102: #1A202C;
    --primary-200: #004035;
    --primary-300: #000000;
    --primary-400: rgb(38, 132, 254);
    --primary-500: rgb(38, 132, 254);
    --primary-600: rgb(250, 139, 13);
    --primary-700: rgb(230, 232, 235);
    --primary-800: rgba(230, 232, 235, 0.2);
    --primary-900: rgba(230, 232, 235, 0.2);
    --primary-905: rgba(3, 218, 85);
}

[data-theme = 'light'] {
    --primary-100: #1A202C;
    --primary-102: #ffffff;
    --primary-200: #ffffff;
    --primary-300: #4F4174;
    --primary-400: rgb(179, 202, 255);
    --primary-500: #1F0D4C;
    --primary-600: rgb(250, 139, 13);
    --primary-700: rgb(230, 232, 235);
    --primary-800: rgba(230, 232, 235, 0.2);
    --primary-900: rgba(230, 232, 235, 0.2);
    --primary-905: rgba(3, 218, 85);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Comfortaa', cursive;
}

h1,h2, h3, h4, h5, h6 {
    font-family: 'Montserrat Alternates', sans-serif;
}

p, span, li {
    font-family: 'Comfortaa', cursive;
}

ul li {
    list-style-type: none;
    padding-left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

a {
    text-decoration: none;
}

p {
    margin-top: 0;
    margin-bottom: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0 !important;
}
.toast-message {
    height: 3rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;