.testimonies {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 3rem 0;
}

.testimonie-1 h1 {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 30px;
    margin-bottom: 2rem;
    color: var(--primary-5001);
}

.testimonie-1 h1::after {
    content: '';    
    position: absolute;
    height: 5px;
    left: 50%;
    bottom: -15px;
    width: 150px;
    border-radius: 10px;
    background: var(--primary-5001);
    transform: translateX(-50%);
}

.testimonie-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 15.5rem;
    height: 16.5rem;
    background: var(--colorwhite);
    box-shadow: 0px 7.18089px 5px -3.59044px rgba(22, 34, 51, 0.08);
    border-radius: 11.7153px;
    margin-bottom: 4rem;
}

.testimonie-box h2 {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
}

.testimonie-box p {
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6rem;
    height: 45%;
    text-shadow: 0 0 80px rgba(7, 89, 197, 0.75), 0 0 32px rgb(65 120 255 / 24%);
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #2684fe;
    }

    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #2684fe;
    }
}

.rating-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.material-icons-round {
    color: var(--primary-7001);
}


.rating-container .clicked {
    color: var(--primary-6001);
}

.line {
    width: 195.65px;
    height: 0px;
    border: 0.897611px solid var(--primary-7001);
}

.mySwiper {
    margin: 0 auto;
    width: 49.6rem;
}

.swiperSlide {
    width: max-content !important;
    padding: 0 0.5rem;
}

@media screen and (max-width: 1200px) {
    .testimonies {
        margin: 1rem 0;
    }
}
@media screen and (max-width: 853px) {
    .mySwiper {
        margin: 0 auto;
        width: 15.5rem;
    }

    .swiperSlide {
        width: 15.5rem;
        padding: 0;
    }
}