.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10rem 12rem 1rem;
    background-color: var(--primary-3001);
    color: var(--primary-1001);
    height: max-content;
    gap: 1rem;
    overflow: hidden;
}

.custom-shape-divider-top-1675361871 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1675361871 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 144px;
}

.custom-shape-divider-top-1675361871 .shape-fill {
    fill: #ffffff;
}

.container-1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
}

.footer-1 {
    width: 38%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 5rem;
}

.footer-1 p {
    margin-top: 1rem;
}

.footer-img {
    width: 20rem;
}

.footer-img img {
    width: 100%;
}

.footer-1 .download-btns {
    width: 91%;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.footer-1 .download-btn {
    width: 12rem;
    height: 3rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

.footer-1 .download-btn:hover {
    transform: scale(0.98);
    transition: all 0.5s;
}

.footer-1 .download-btn img {
    width: 100%;
}


.footer-2 {
    width: 62%;
    min-height: 250px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 3rem;
}

.footer-1-1 ul,
.footer-2-2 ul,
.footer-2-3 ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9rem;
    font-family: 'Montserrat Alternates';
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 30px;
    letter-spacing: 0.055em;
    color: var(--primary-1001);
    padding-left: 0;
}

.footer-1-1 a {
    color: var(--primary-1001);
}

.footer-1-1 a:hover{
    color: var(--primary-5001);
}

.footer-2-3 {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.footer-2-header {
    font-family: 'Montserrat Alternates';
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 28px;
    text-align: center;
    color: var(--primary-5001);
}

.mail {
    width: 110%;
    height: 3rem;
    border-radius: 0;
    background-color: var(--primary-1001);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mail input {
    width: 80%;
    border: none;
    padding-left: 0.5rem;
}

.mail input::placeholder {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 1rem;
    color: var(--primary-3001);
}

.mail input:focus, .mail button:focus {
    outline: none;
}

.mail button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0;
    background-color: var(--primary-3001);
}

.button-arrow {
    color: var(--primary-1001);
    font-size: 1.5rem;
    animation: cowmove 0.7s infinite;
}

@keyframes cowmove {
    0% {
        transform: translateX(2px);
    }

    100% {
        transform: translateX(-2px);
    }
}

.footer-2-2 .login-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    border: 1px solid var(--primary-1001);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-1001);
}

.footer-2-2 .login-btn:before {
    position: absolute;
    content: "";
    right: -5px;
    bottom: 5px;
    display: flex;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    border: 1px solid var(--primary-1001);
    color: var(--primary-5001);
}

.footer-2-2 .signup-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    background-color: var(--primary-1001);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-3001);
}

.footer-2-2 .signup-btn:before {
    position: absolute;
    content: "";
    right: -5px;
    bottom: 5px;
    display: flex;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    border: 1px solid var(--primary-1001);
    color: var(--primary-3001);
}

.login-btn:hover,
.signup-btn:hover {
    transform: scale(0.98);
    transition: all .45s;
}

.container-2 {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}

.container-2-2 p {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
}

@media screen and (max-width: 1440px) {
    .footer {
        height: fit-content;
        padding: 10rem 1rem 1rem;
        gap: 0.5rem;
    }

    .container-1 {
        flex-direction: row;
        gap: 0.5rem;
    }

    .footer-2 {
        gap: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        padding: 10rem 1rem 1rem;
    }

    .container-1 {
        flex-direction: column;
        gap: 3rem;
    }

    .footer-1 {
        width: 80%;
        align-items: center;
        justify-content: center;
    }

    .footer-1 p {
        width: 90%;
    }

    .footer-2 {
        width: 80%;
        justify-content: space-between;
        gap: 2rem;
        min-height: fit-content;
        padding-bottom: 1rem;
    }

    .footer-1-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .container-2 {
        padding: 0 3rem 0;
    }

    .footer-1-1 .download-btns {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 903px) {
    .container-2 {
        margin-top: 0.5rem;
        flex-direction: column;
        height: fit-content;
        min-height: fit-content;
        align-items: center;
        gap: 0rem;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        height: fit-content;
        padding: 8rem 0rem 1rem;
        padding-inline: 50px;
        gap: 0.5rem;
    }

    .custom-shape-divider-top-1675361871 {
        width: 120%;
    }

    .footer-1 {
        min-height: fit-content;
    }
    
    .footer-2 {
        flex-direction: column;
        display: inline-grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-2-3 {
        width: 80%;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        grid-column: 1 / 4;
    }

    .download-btns {
        width: 90%;
    }
}

@media screen and (max-width: 580px) {
    .footer {
        height: fit-content;
        padding: 8rem 0rem 1rem;
        gap: 0.5rem;
    }

    .custom-shape-divider-top-1675361871 {
        width: 130%;
        overflow-x: hidden;
    }

    .footer-1 {
        min-height: fit-content;
    }

    .footer-2 {
        width: fit-content;
    }

    .footer-2-3 {
        width: 100%;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        grid-column: 1 / 4;
    }

    .footer-img {
        width: 14rem;
    }
}