.allUsers-container {
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.allUsers-1 {
    width: 100%;
    height: fit-content;
}

.allUsers-1 h4 {
    font-size: 1.5rem;
    width: 100%;
    padding: 0.5rem 2rem;
    background: var(--primary-500);
    border-radius: 0 0 10px 10px;
    color: var(--primary-100);
    letter-spacing: 3px;
}

.allUsers-2 {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    flex-direction: column;
    padding: 0 1rem;
}

.allUsers-2-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.allUsers-2-1 h3 {
    font-weight: 700;
    font-size: 1.3rem;
}

.allUsers-2-1 p {
    background-color: var(--primary-300);
    color: var(--primary-100);
    padding: 1rem;
    font-size: 1rem;
    border-radius: 10px;
}

.allUsers-2-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.allUsers-2-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
}

.allUsers-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.allUsers-heading .allUsers-icon-container {
    color: var(--primary-300);
    background-color: var(--primary-100);
    padding: 0.4rem;
    height: fit-content;
    width: fit-content;
    border-radius: 30px 30px 0 0;
}

.allUsers-heading .allUsers-icon {
    font-size: 2rem;
}

.allUsers-heading h2 {
    color: var(--primary-100);
    background-color: var(--primary-300);
    font-size: 1.2rem;
    border: 3px solid var(--primary-100);
    border-radius: 10px;
    width: fit-content;
    padding: 0.3rem 1rem;
    margin: 0 auto;
}

.allUsers-details {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    margin: 1rem 0;
    background-color: var(--primary-100);
    gap: 0.5rem;
    border-radius: 10px 25px 10px 10px;
}

.allUsers-details h5 {
    text-align: left;
    width: 100%;
    font-size: 0.9rem;
    color: var(--primary-200);
    padding-bottom: 2px;
    border-bottom: 1.5px solid var(--primary-700);
}

.allUsers-details h5 span {
    color: var(--primary-300);
    font-size: 1rem;
    display: block;
    word-wrap: break-word;
}

.allUsers-details-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-500);
    right: -1%;
    top: -1%;
}

.allUsers-details-circle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-300);
    border: 2px dotted var(--primary-400);
}

.allUsers-2-2 section {
    width: 100%;
    text-align: center;
    max-width: 700px;
    margin: 1rem auto;
    padding: 2rem;
    border-radius: 10px;
    background-color: var(--primary-300);
}

.idcard-img {
    width: 100%;
}

.idcard-img img {
    width: 60%;
}

@media screen and (max-width: 1200px) {
    .allUsers-container {
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .allUsers-2-2 section {
        width: 95%;
    }

    .allUsers-2-2 p {
        font-size: 0.83rem;
    }
}

