

.dashboards-container {
    display: flex;
    height: fit-content;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.dashboards-1 {
    width: 100%;
    height: fit-content;
}

.charts-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-300);
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    flex-direction: row;
    overflow-y: hidden;
    border-radius: 10px;
}

.charts-container-1, .charts-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
}

.charts-container p {
    all: unset;
    color: var(--primary-1001);
}

.chartContainer {
    width: 100px;
    height: 100px;
    max-width: 120px;
    overflow-y: hidden;
}

.dashboards-2 {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.dashboards-2-1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.dashboardsbalance {
    color: var(--primary-400);
        letter-spacing: 5px;
        text-shadow:
            -1px -1px 1px #446bbd,
            2px 2px 1px #363636;
}

.dashboardsbalance2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: row;
    margin: 1rem auto;
    height: 15rem;
    background-color: var(--primary-500);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.dashboardsbalance2-1 {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: column;
    margin: 1rem auto;
    border-radius: 10px;
    width: 45%;
    height: 80%;
    background-color: var(--primary-100);
    gap: 0.5rem;
    border-radius: 10px;
    box-shadow: rgba(79, 65, 116, 0.4) 5px 5px, rgba(79, 65, 116, 0.3) 10px 10px, rgba(79, 65, 116, 0.2) 15px 15px, rgba(79, 65, 116, 0.1) 20px 20px, rgba(79, 65, 116, 0.05) 25px 25px;
}

.dashboardsbalance2-1-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    border-bottom: 2px ridge var(--primary-700);
}

.dashboardsbalance2-1-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.dashboardsbalance2-1-iconcontainer {
    padding: 0.6rem;
    background-color: var(--primary-300);
    color: var(--primary-100);
    border-radius: 50%;
}

.dashboardsbalance2-1-icon {
    font-size: 1.5rem;
}

.dashboardsbalance2 p {
    font-size: 1rem;
    letter-spacing: 2px;
    color: var(--primary-400);
    font-weight: 600;
}

.dashboardsbalance2 h2 {
    font-size: 1.8rem;
    letter-spacing: 2px;
    color: var(--primary-400);
}

.dashboards-links-container {
    margin-top: 2rem;
}

.dashboards-links {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.dashboards-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
    flex-direction: column;
    gap: 0.5rem;
    width: 7rem;
    height: 4rem;
    background-color: var(--primary-500);
    color: var(--primary-102);
    transition: 0.3s ease-in;
}

.dashboards-link-icon {
    font-size: 1.2rem;
}

.dashboards-link:hover {
    background-color: var(--primary-300);
    color: var(--primary-100); 
    transform: scale(0.98);
}

.dashboards-btn:active {
    transform: scale(0.98);
}

.dashboards-3 {
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: fit-content;
}

.dashboards-3 h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
    letter-spacing: 3px;
    font-size: 2.3rem;
    color: var(--primary-100);
    background-color: var(--primary-300);
    padding: 0.5rem 0.5rem 0;
    border-bottom: 2px solid var(--primary-100);
    border-top: 2px solid var(--primary-100);
}

.dashboards-3-main {
    width: 100%;
    padding: 1.5rem;
    background-color: var(--primary-800);
    border-radius: 0 0 10px 10px;
}

.dashboards-3 h5, .dashboards-3 h4, .dashboards-3 p{
    margin-bottom: 0;
}
.dashboards-3 h5 {
    color: var(--primary-102);
}

.dashboards-3-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: var(--primary-100);
    color: var(--primary-300);
    width: 100%;
    height: 4rem;
    border-bottom: 3px solid var(--primary-700);
    border-top: none;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
}

.dashboards-3-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
}

.dashboards-3-icon {
    width: 50px;
    height: 50px;
    font-size: 2rem;
    color: var(--primary-500);
}

.dashboards-3-icon-btc {
    display: inline;
    width: 20px;
    height: 20px;
    font-size: 1rem;
    color: var(--primary-500);
    margin-left: 0.5rem;
}

.dashboards-3-1-1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding-left: 1rem;
    border-left: 1px solid var(--primary-700);
}

.dashboards-3-1-1 p {
    font-size: 1rem;
    color: var(--primary-400);
}

.dashboards-3-2 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.dashboards-3-2 h5 {
    color: var(--primary-300);
}

.dashboards-3-2 p {
    font-size: 1rem;
    color: var(--primary-400);
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.transaction-error {
    color: var(--primary-300);
    font-size: 2rem;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    cursor: pointer;
    gap: 0.5rem;
    width: 95%;
    height: 2rem;
    color: var(--primary-1001);
    margin-top: 2rem;
}

.pagination a {
    display: block;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #6c7ac9;
}

.pagination li {
    display: block;
}

.pagination__link {
    font-weight: bold;
}

.pagination__link--active a {
    color: #fff;
    background: #6c7ac9;
}

.pagination__link--disabled a {
    color: rgb(198, 197, 202);
}

@media screen and (max-width: 1300px) {
    .dashboards-2-1 {
        width: 100%;
    }

    .dashboards-3 {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .dashboards-2-1 {
        width: 100%;
    }
    
    .dashboardsbalance2 {
        height: 13rem;
    }
    
    .dashboardsbalance2-1 {
        width: 45%;
    }
}

@media screen and (max-width: 850px) {
    .dashboardsbalance2-1 p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 630px) {
    .dashboardsbalance2 {
        flex-direction: column;
        height: fit-content;
    }

    .dashboardsbalance2-1 {
        width: 95%;
        padding: 0.5rem;
    }

    .dashboards-3-main {
        padding: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .dashboards-link {
        font-size: 0.8rem;
    }

    .dashboards-3-main {
        padding: 0.5rem;
    }

    .dashboards-2-1 h2 {
        font-size: 2rem;
    }
}
