.buy-container {
    display: flex;
    height: fit-content;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.buy-1 {
    width: 100%;
    height: fit-content;
}

.buy-2 {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 0 auto;
}

.buy-2-1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.buybalance {
    text-align: center;
    color: var(--primary-200);
}

.buy-2-1-1 p {
    background-color: var(--primary-300);
    color: var(--primary-100);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    margin-bottom: 0 !important;
}

.buy-section {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: max-content;
    background-color: var(--primary-300);
    padding: 1rem;
    color: var(--primary-100);
    margin: 0 auto;
    margin-bottom: 2rem;
    transition: .8s;
    box-shadow: 1px 1px 2px 3px rgba(22, 34, 51, 0.08);
}

.buy-section h5 {
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
}

.buy-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4rem;
}

.withdraw-icon {
    font-size: 2rem;
    color: var(--primary-100);
    transition: .3s;
}

.withdraw-icon.applepay {
    font-size: 3rem;
}

.buy-section h2 {
    margin-bottom: 1.2rem;
}

.buy-section p {
    margin-bottom: 1.2rem;
}

.buy-section .toggle-menu {
    height: fit-content;
    display: none;
    transition: .3s;
}

.buy-section .toggle-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-100);
    padding: 1rem 0;
}

.toggle-menu input,
.toggle-menu textarea,
.toggle-menu select,
.toggle-menu input:active,
.toggle-menu textarea:active,
.toggle-menu select:active {
    outline: none;
}

.toggle-menu form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.toggle-menu input {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    padding: 0 1rem;
    width: 90%;
    height: 3rem;
    border-radius: 10px;
}

.toggle-menu input::placeholder,
.toggle-menu textarea::placeholder {
    color: var(--primary-700);
}

.toggle-menu label {
    color: var(--primary-300);
    display: block;
    margin-top: 1rem;
}

.toggle-menu textarea {
    border: 3px solid var(--primary-700);
    color: var(--primary-300);
    width: 90%;
    height: 5rem;
    border-radius: 10px;
    resize: none;
    padding: 3px;
}

.toggle-menu select {
    background-color: var(--primary-300);
    color: var(--primary-100);
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
}

.toggle-menu button {
    font-family: 'Montserrat Alternates', sans-serif;
    display: block;
    margin: 1rem auto 1rem;
    padding: 0.5rem 0.1rem;
    border: none;
    border-radius: 40px;
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--primary-500);
    color: var(--primary-100);
    transition: all 0.4s ease-in;
}

.expiry-input {
    display: inline !important;
    width: 100px !important;
}

.cvv-input {
    display: inline !important;
    width: 100px !important;
}

.expiry-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.buy-btn:active {
    transform: scale(0.98);
}

.buy-3 {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.buy-3 p {
    font-style: italic;
}

.buy-3-icon {
    font-size: 2rem;
}

.buy-4 {
    width: 90vw;
}

.coin-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.coin-details {
    overflow-x: auto;
}

.coin-search {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.coin-search form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.coin-input {
    padding-left: 16px;
    max-width: 900px;
    width: 81%;
    align-self: center;
    height: 50px;
    border-radius: 10px 10px 0 0;
    border: none;
    color: var(--primary-1001);
    background-image: linear-gradient(-225deg,
        var(--primary-500) 0%,
        #7918f2 48%,
        var(--primary-500) 100%);
}

.coin-input::placeholder {
    color: var(--primary-700)
}

.coin-input:focus {
    border: none;
    outline: none;
}

@media screen and (max-width: 1300px) {
    .buy-2-1 {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .buy-2-1 {
        width: 90%;
    }

    .buy-section-1 {
        gap: 3rem;
    }
}

@media screen and (max-width: 850px) {
    .buy-2 {
        padding: 1rem;
    }

    .buy-2-1 {
        width: 100%;
    }

    .buy-section {
        width: 100%;
    }

    .buy-section h5 {
        font-size: 1rem;
    }

    .withdraw-icon {
        font-size: 1.5rem;
    }

    .buy-section-1 {
        gap: 2rem;
    }

    .toggle-menu input {
        border: 3px solid var(--primary-700);
        color: var(--primary-300);
        padding: 0 1rem;
        width: 95%;
    }

    .toggle-menu input::placeholder {
        color: var(--primary-700);
    }

    .toggle-menu label {
        color: var(--primary-300);
        display: block;
        margin-top: 1rem;
    }

    .toggle-menu textarea {
        width: 95%;
        height: 7rem;
    }
}

@media screen and (max-width: 500px) {
    .buybalance {
        font-size: 1.2rem;
    }
}